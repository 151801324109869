import React, {useState, useEffect, useRef} from 'react'
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom'
import convertCm from '../../Utilities/convertCm'
import { useSelector } from 'react-redux'
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import { motion } from 'framer-motion';
import NoResults from '../../Utilities/NoResults'
import { useParams } from 'react-router-dom'

export default function AssetsTable({assetData, searchQuery, resultsFound, loading, setPage=null, page=null, subsequentLoading=null}) {

    const [assets, setAssets] = useState([])
    useEffect(() => {
        setAssets(assetData)
    }, [assetData])
    const permissions = useSelector((state) => state.loginSlice.permissions);

    const { id } = useParams()

    
    function handleScroll(container) {
      const scrollPosition = container.scrollTop + container.clientHeight;
      const threshold = container.scrollHeight * 0.7; // 70% of the total scroll height
    
      if (scrollPosition >= threshold) {
        if (!subsequentLoading) {
          setPage(prevPage => prevPage + 1);
        }
      }
    }

  const tableRef = useRef(null);

  const handleDdAsset = (index) => {
    document.getElementById(`ddAsset${index}`).classList.toggle("show");
  

  // Close the dropdown if the user clicks outside of it
  window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
  }
}

  return (
    <>
    {!loading ? 
      resultsFound ?
      <div className="table-responsive"
        ref={tableRef}
        style={{ height: '70vh', overflowY: 'auto' }}
        onScroll={(setPage !== null && page !== null && subsequentLoading !== null) ? () => handleScroll(tableRef.current) : null}
      >
          {/*begin::Table*/}
            <motion.table
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
              >
            {/*begin::Table head*/}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-300px">Asset Name</th>
                <th className="min-w-150px">Warehouse</th>
                <th className="min-w-150px">Zone</th>
                <th className="min-w-150px">Brand</th>
                <th className="min-w-150px">Client</th>
                <th className="min-w-150px">Project</th>
                {(searchQuery.length === 0) &&
                <th className="min-w-100px">Quantity</th>}
                <th className="min-w-150px">Asset Owner</th>
                <th className="min-w-150px">Asset Length</th>
                <th className="min-w-150px">Asset Width</th>
                <th className="min-w-150px">Asset Height</th>
                <th className="min-w-150px">Created at</th>
                {/* <th className="min-w-150px">Created by</th> */}
              </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
              {assets.map((asset, index) => 
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {!asset.first_image ? <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path>
                    <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span> :
                    <span className='symbol-label bg-light'>
                      <img src={asset.first_image.image_url} className="h-100 align-self-end" alt="" style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                    </span>}
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      {(parseInt(asset.quantity) === 1) ? 
                        <Link to={`/asset-management/detailed-asset/${asset.assets[0].id}`} className="text-dark fw-bolder text-hover-primary fs-6">{asset.asset_name}</Link>
                      : searchQuery.length > 0 ? <Link to={`/asset-management/detailed-asset/${asset.id}`} className="text-dark fw-bolder text-hover-primary fs-6">{asset.asset_name}</Link> :
                      <a onClick={() => {handleDdAsset(index)}} className="dropbtn text-dark fw-bolder text-hover-primary fs-6">{asset.asset_name}</a>}
                      {asset.active ? <span style={{maxWidth: 'fit-content'}} className="mt-2 fw-bold badge badge-success badge-sm d-block text-white">Active</span>: 
                      <span style={{maxWidth: 'fit-content'}} className="mt-2 fw-bold badge badge-danger badge-sm d-block text-white">Inactive</span>}
                    </div>
                  </div>
                  <div className="dropdown">
                  <div id={`ddAsset${index}`} className="dropdown-content" style={{
                      zIndex: 105,
                      position: 'fixed',
                      inset: '0px 0px auto auto',
                      margin: '0px',
                      transform: 'translate3d(-43vw, 290px, 0px)',
                      maxHeight: '300px',
                      overflow: 'auto'
                  }}>
                    {(asset.assets) && asset.assets.map((asset, index) => {
                      return (
                        <Link to={`/asset-management/detailed-asset/${asset.id}`} key={index}>{asset.name}</Link>
                      )
                    })}
                  </div>
                </div>
                </td>
                <td>
                  {asset.asset_warehouse ? <Link to={`/warehouse-management/warehouses/detailed-warehouse/${asset.asset_warehouse.warehouse_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_warehouse.warehouse_name}</Link> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  {asset.asset_zone ? <a  className="text-dark fw-bolder d-block fs-6">{asset.asset_zone}</a> : <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  <Link to={`/brand-management/detailed-brand/${asset.asset_brand.brand_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_brand.brand_name}</Link>
                </td>
                <td>
                  {asset.asset_client ?
                  <Link to={`/client-management/clients/detailed-client-organization/${asset.asset_client.client_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_client.client_name}</Link>
                  : <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}</td>
                <td>
                  <Link to={`/project-management/detailed-project/${asset.asset_project.project_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_project.project_name}</Link>
                </td>
                {(searchQuery.length === 0) &&
                <td>
                  <a  className="text-dark fw-bolder d-block fs-6">{asset.quantity}</a>
                </td>}
                <td>
                  <Link to={`/user-management/users/user-profile/${asset.brand_representative.brand_representative_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.brand_representative.brand_representative_name}</Link>
                </td>
                <td>
                  {asset.asset_length ? <a  className="text-dark fw-bolder d-block fs-6">{convertCm(asset.asset_length)}</a> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  {asset.asset_width ? <a  className="text-dark fw-bolder d-block fs-6">{convertCm(asset.asset_width)}</a> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  {asset.asset_height ? <a  className="text-dark fw-bolder d-block fs-6">{convertCm(asset.asset_height)}</a> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  <a className="text-dark fw-bolder d-block fs-6">{parseDateTimestamp(asset.created_at)}</a>
                </td>
                {/* <td>
                  <Link to={`/user-management/users/user-profile/${asset.created_by}`} className="text-dark text-hover-primary fw-bolder d-block fs-6">{asset.created_by_name}</Link>
                </td> */}
              </tr>)}
            </tbody>
            {/*end::Table body*/}
          </motion.table>
          </div> : 
          <NoResults />:
            <SkeletonLoader />}
          {/*end::Table*/}
    </>
  )
}
