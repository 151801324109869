import React from 'react'
import { Link } from 'react-router-dom'
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import { motion } from 'framer-motion';
import SkeletonLoader from '../../Warehouse/SkeletonLoader/SkeletonLoader';
import NoResults from '../../Utilities/NoResults';

export default function ItemSerialTable({items, loading, resultsFound, firstImage}) {
  return (
    <>
    {!loading ? 
      resultsFound ?
      <div className="table-responsive">
        {/*begin::Table*/}
        <motion.table
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }} style={{fontWeight: '600'}} 
        className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/*begin::Table head*/}
            <thead>
                <tr className="fwt-bolder text-muted bg-light">
                    <th className="ps-4 min-w-200px rounded-start">Item Name</th>
                    <th className="min-w-200px">Item Serial Number</th>
                    <th className="min-w-125px">Asset</th>
                    <th className="min-w-125px">Brand</th>
                    <th className="min-w-125px">Project</th>
                    <th className="min-w-125px">Warehouse</th>
                    <th className="min-w-125px">Warehouse Zone</th>
                    <th className="min-w-125px">Created At</th>
                </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
                {items && items.map((item) => {
                return (<tr>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px me-5">
                                <span className="symbol-label bg-light">
                                {!item.image ? <span className="svg-icon svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="black"></path><path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z" fill="black"></path></svg></span>
                                : <img src={item.image.image_url} className="h-100 align-self-end" alt="" style={{width: '100%', height: '100%', borderRadius: '5px'}} />}</span>
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                                <a className="text-dark fwt-bolder mb-1 fs-6">{item.item_name_number}</a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a className="text-dark fwt-bolder mb-1 fs-6">{item.serial_code}</a>
                    </td>
                    <td>
                        {item.asset ? <Link to = {`/asset-management/detailed-asset/${item.asset.id}`} className="text-dark fwt-bolder text-hover-primary d-block fs-6">{item.asset.name}</Link> : <a className="text-dark fwt-bolder mb-1 fs-6">Not Added</a>}
                    </td>
                    <td>
                        {item.brand ? <Link to = {`/asset-management/detailed-asset/${item.brand.id}`} className="text-dark fwt-bolder text-hover-primary d-block fs-6">{item.brand.name}</Link> : <a className="text-dark fwt-bolder mb-1 fs-6">Not Added</a>}
                    </td>
                    <td>
                        {item.project ? <Link to = {`/asset-management/detailed-asset/${item.project.id}`} className="text-dark fwt-bolder text-hover-primary d-block fs-6">{item.project.name}</Link> : <a className="text-dark fwt-bolder mb-1 fs-6">Not Added</a>}
                    </td>
                    <td>
                        {item.warehouse ? <Link to = {`/asset-management/detailed-asset/${item.warehouse.id}`} className="text-dark fwt-bolder text-hover-primary d-block fs-6">{item.warehouse.name}</Link> : <a className="text-dark fwt-bolder mb-1 fs-6">Not Added</a>}
                    </td>
                    <td>
                        {item.zone ? <a className="text-dark fwt-bolder mb-1 fs-6">{item.zone.name}</a> : <a className="text-dark fwt-bolder mb-1 fs-6">Not Added</a>}
                    </td>
                    <td>
                        <a className="text-dark fwt-bolder d-block mb-1 fs-6">{parseDateTimestamp(item.created_at)}</a>
                    </td>
                </tr>)})}
            </tbody>
                    {/*end::Table body*/}
                </motion.table>
                {/*end::Table*/}
            </div> : 
                <NoResults />:
            <SkeletonLoader />}
            </>
            
  )
}
