import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import api from '../../store/api'
import {useSelector} from 'react-redux'
import parseTimestamp from '../Utilities/parseTimeStamp'
import MainModal from '../Modals/MainModal'
import UpdateUserProfileModalBody from '../UpdateUserProfileModalBody/UpdateUserProfileModalBody'
import FeedbackModal from '../Utilities/FeedbackModal';
import UpdateUserEmailModalBody from '../UpdateUserEmailModalBody/UpdateUserEmailModalBody'
import AssertiveModal from '../Utilities/AssertiveModal'
import UpdateUserPasswordModalBody from '../UpdateUserPasswordModalBody/UpdateUserPasswordModalBody'
import { useNavigate } from 'react-router-dom'
import PermissionsModal from '../Modals/PermissionsModal'
import parseDateTimestamp from '../Utilities/parseDateTimeStamp.'
import ClientAssets from './ClientAssets'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import RestrictedModal from '../withAuthorization/RestrictedModal'

export default function UserProfile() {
	const [currentTab, setCurrentTab] = useState(1)
    const {id} = useParams()
	const [user, setUser] = useState({})
	const [userProfile, setUserProfile] = useState({})
	const [latestEmailVerification, setLatestEmailVerification] = useState({})
	const [latestAccountApproval, setlatestAccountApproval] = useState([])
	const [userSettings, setUserSettings] = useState([])
	const [forceResetPasswords, setForceResetPasswords] = useState([])
	const [groups, setGroups] = useState([])
	const [userModalShow, setUserModalShow] = useState(false)
	const [emailChangeModalShow, setEmailChangeModalShow] = useState(false)
	const [passwordModalShow, setPasswordModalShow] = useState(false)
	const [changeEmailStep, setchangeEmailStep] = useState(false)
	const [userEventLogs, setUserEventLogs] = useState([])
	const [showAssertiveModal, setShowAssertiveModal] = useState({
		show: false, 
		promptText: '',
		btnPrimary: {show: false, text: ''},
		btnSecondary: {show: false, text: ''}
	})
	const navigate = useNavigate()
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })
    const token = useSelector((state) => state.loginSlice.token);
	const loggedInUser = useSelector((state) => state.loginSlice.user);
	const permissions = useSelector((state) => state.loginSlice.permissions);
	const [showPermsModal, setShowPermsModal] = useState(false)
	const [rolesStr, setRolesStr] = useState("")
	const [latestAccountStatus, setLatestAccountStatus] = useState([])

	const stringifyRoles = () => {
		let stringifiedRoles = ""
		if (groups.length <= 0) {
			stringifiedRoles = `No role has been assigned to ${user.profile ? user.profile.first_name : 'user'}.`
		} else if (groups.length == 1) {
			stringifiedRoles = groups[0]["name"]
		} else {
			for (let i = 0; i < groups.length; i++) {
				if (i === groups.length - 1) 
					stringifiedRoles = stringifiedRoles.concat(` and ${groups[i].name}`)
				else if (i === groups.length - 2) 
					stringifiedRoles = stringifiedRoles.concat(` ${groups[i].name}`)
				else
					stringifiedRoles = stringifiedRoles.concat(` ${groups[i].name},`)
			}
		}
		setRolesStr(stringifiedRoles)
	}

	const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

	useEffect(() => {
		stringifyRoles()
	}, [groups])

    useEffect(() => {
		let _user;
		api.get(`/api/users/get_user/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})

		.then((response) => {
		if (response.status === 200) {
				setUser(response.data.user)
				setUserProfile(response.data.user.profile)
				setLatestEmailVerification(response.data.user.latest_email_verification)
				setlatestAccountApproval(response.data.user.latest_account_approval)
				setUserSettings(response.data.user.user_settings)
				setForceResetPasswords(response.data.user.force_reset_passwords)
				setGroups(response.data.user.groups)
				setLatestAccountStatus(response.data.user.latest_account_status)
				setUserEventLogs(response.data.user.user_event_logs)
				_user = response.data.user
				if (!_user.is_client)
					setCurrentTab(2)
				if ((!permissions['view_client_profile'] && _user.is_client && loggedInUser.id !== _user.id)) {
					setShowPermsModal(true)
				} else if ((!permissions['view_crew_profile'] && _user.is_crew && loggedInUser.id !== _user.id)) {
					setShowPermsModal(true)
				} else if ((!permissions['view_staff_profile'] && _user.is_staff && loggedInUser.id !== _user.id)) {
					setShowPermsModal(true)
				} 
		}
		})

		.catch((error) => {
			throw new Error (error.message)
		})
			
    }, [id])


	const handleShowResetPasswordAssertiveModal = (evt) => {
		evt.preventDefault()
		setShowAssertiveModal({
			show: true, 
			promptText: `Are you sure you want to reset ${userProfile.first_name}'s account password?`,
			btnPrimary: {show: true, text: 'Yes, Reset it'},
			btnSecondary: {show: true, text: 'No, Take me back'}
		})
	}

	const handleResetPassword4User = (status) => {
		if (status === true) {
			
			api.post(`/api/users/forgot-password/`, {email: user.email, reset_by: loggedInUser.id}, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			})

			.then((response) => {
				if (response.status == 200) {
					setShowAssertiveModal({
						show: false, 
						promptText: '',
						btnPrimary: {show: false, text: ''},
						btnSecondary: {show: false, text: ''}
					})
					setRes({
						message: response.data.message,
						severity: "success",
						show: true
					})
				}
			})

			.catch((error) => {
				setRes({
					message: error.response.data.message,
					severity: "error",
					show: true
				})
			})

		} else {
			setShowAssertiveModal({
				show: false, 
				promptText: '',
				btnPrimary: {show: false, text: ''},
				btnSecondary: {show: false, text: ''}
			})
		}
	}

  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
				{isOpen && (
					<Lightbox
						mainSrc={userProfile.profile_photo_path}
						onCloseRequest={() => setIsOpen(false)}
						onMovePrevRequest={() =>
							setPhotoIndex((photoIndex + images.length - 1) % images.length)
						}
						onMoveNextRequest={() =>
							setPhotoIndex((photoIndex + 1) % images.length)
						}
					/>
				)}

				<AssertiveModal 
					show = {showAssertiveModal.show}
					promptText = {showAssertiveModal.promptText} 
					btnPrimary = {{show: showAssertiveModal.btnPrimary.show, text: showAssertiveModal.btnPrimary.text}} 
					btnSecondary = {{show: showAssertiveModal.btnSecondary.show, text: showAssertiveModal.btnSecondary.text}} 
					handleActionBtnClick = {handleResetPassword4User}
				/>

				<FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
                    setRes({
                        message: "",
                        severity: "success",
                        show: false
                    })
                }}/>
				
				{!showPermsModal ?
					<div className="post d-flex flex-column-fluid" id="kt_post">
						{/*begin::Container*/}
						<div id="kt_content_container" className="container-xxl">
							{/*begin::Layout*/}
							<div className="d-flex flex-column flex-xl-row">
								{/*begin::Sidebar*/}
								<div className="flex-column flex-lg-row-auto w-xl-350px mb-10">
									{/*begin::Card*/}
									<div className="card mb-5 mb-xl-8">
										{/*begin::Card body*/}
										<div className="card-body">
											{/*begin::Summary*/}
											{/*begin::User Info*/}
											<div className="d-flex flex-center flex-column py-2">
												{/*begin::Avatar*/}
												<div className="symbol symbol-100px symbol-circle mb-3">
													{ userProfile ?
													userProfile.profile_photo_path && userProfile.profile_photo_path !== '' ?
														<img src={userProfile.profile_photo_path} 
															alt={`${userProfile.first_name}'s profile picture.`}
															onClick={() => { setIsOpen(true); setPhotoIndex(0); }}
															style={{ cursor: 'pointer' }} 
														/>
													:
														<span className="svg-icon svg-icon-muted svg-icon-5hx">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path><path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path></svg></span>
													:
													<span className="svg-icon svg-icon-muted svg-icon-5hx">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path><path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path></svg></span>
													}
												</div>
												{/*end::Avatar*/}
												{/*begin::Name*/}
												<a  className="fs-5 text-gray-800 text-hover-primary fwt-bolder mb-2">{userProfile ? `${userProfile.first_name} ${userProfile.last_name}` : null}</a>
												{/*end::Name*/}
												{/*begin::Position*/}
												<div className="mb-9">
													{/*begin::Badge*/}
													{userProfile ? 
													user.is_staff ? userProfile.occupation !== '' &&  userProfile.occupation !== null && <div className="badge badge-lg badge-light-primary d-inline">{userProfile.occupation}</div> :
													user.is_crew ? userProfile.crew_role !== '' && userProfile.crew_role !== null && <div className="badge badge-lg badge-light-primary d-inline">{userProfile.crew_role}</div> :
													<div className="badge badge-lg badge-light-primary d-inline">Beysix Client</div> :
													null }
													{/*begin::Badge*/}
												</div>
											</div>
											{/*end::User Info*/}
											{/*end::Summary*/}
											{/*begin::Details toggle*/}
											<div className="d-flex flex-stack fs-5 py-3">
												<div className="fwt-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">Details
												<span className="ms-2 rotate-180">
													{/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
													<span className="svg-icon svg-icon-3">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
														</svg>
													</span>
													{/*end::Svg Icon*/}
												</span></div>
												{(permissions["change_client_profile"] && user.is_client && loggedInUser.id !== user.id) && 
												<span data-bs-toggle="tooltip" data-bs-trigger="hover">
													<a  className="btn btn-sm btn-light-primary" i-attr = 'btn-modal' onClick={(evt) => {
														evt.preventDefault();
														setUserModalShow(true)
													}}>Edit</a>
												</span>}
												{(permissions["change_crew_profile"] && user.is_crew && loggedInUser.id !== user.id) && 
												<span data-bs-toggle="tooltip" data-bs-trigger="hover">
													<a  className="btn btn-sm btn-light-primary" i-attr = 'btn-modal' onClick={(evt) => {
														evt.preventDefault();
														setUserModalShow(true)
													}}>Edit</a>
												</span>}
												{(permissions["change_staff_profile"] && user.is_staff && loggedInUser.id !== user.id) && 
												<span data-bs-toggle="tooltip" data-bs-trigger="hover">
													<a  className="btn btn-sm btn-light-primary" i-attr = 'btn-modal' onClick={(evt) => {
														evt.preventDefault();
														setUserModalShow(true)
													}}>Edit</a>
												</span>}
												{(loggedInUser.id === user.id) && 
												<span data-bs-toggle="tooltip" data-bs-trigger="hover">
													<a  className="btn btn-sm btn-light-primary" i-attr = 'btn-modal' onClick={(evt) => {
														evt.preventDefault();
														setUserModalShow(true)
													}}>Edit</a>
												</span>}
											</div>
											{/*end::Details toggle*/}
											<div className="separator"></div>
											{/*begin::Details content*/}
											<div id="kt_user_view_details" className="collapse show">
												<div className="pb-5 fs-6">
													{/*begin::Details item*/}
													{/*begin::Details item*/}
													<div className="fw-bold mt-4">Email</div>
													<div className="text-gray-600">
														<a  className="text-gray-600 text-hover-primary">{user.email}</a>
													</div>
													{/*begin::Details item*/}
													{/*begin::Details item*/}
													{userProfile !== null ?
													userProfile.user_address !== null && userProfile.user_address !== '' ?
													<div>
														<div className="fw-bold mt-4">Address</div>
														<div className="text-gray-600">{userProfile.user_address}</div>
													</div>:
													<div>
														<div className="fw-bold mt-4">Address</div>
														<div className="text-gray-600">Address not added</div>
													</div>: null}
													<div className="fw-bold mt-4">User Type</div>
													{user.is_staff ? <div className="badge badge-sm badge-light-primary mt-2">Staff Member</div> :
													user.is_crew ? <div className="badge badge-sm badge-light-primary mt-2">Crew Member</div> :
													<div className="badge badge-sm badge-light-primary mt-2">Beysix Client</div>}

													<div className="fw-bold mt-4">User Approval Status</div>
													{user.is_approved ? <div className="badge badge-sm badge-success mt-2">Approved</div> :
													<div className="badge badge-sm badge-danger mt-2">Not Approved</div> }

													<div className="fw-bold mt-4">User Verification Status</div>
													{user.is_email_verified ? 
														<>
															<div className="badge badge-sm badge-success mt-2">Verified</div>
															{latestEmailVerification[0] && <span style={{display: "block", marginTop: "10px", fontSize: "11.9px"}}>Verified on: {parseTimestamp(latestEmailVerification[0].verified_on)}</span>}
														</> :
													<div className="badge badge-sm badge-danger mt-2">Not Verified</div> }

													<div className="fw-bold mt-4">User Active Status</div>
													{user.is_active ? <div className="badge badge-sm badge-success mt-2">Active</div> :
													<div className="badge badge-sm badge-danger mt-2">Inactive</div> }

													{user.last_login &&
													<div>
														<div className="fw-bold mt-4">Last Login</div>
														<div className="text-gray-600">{parseDateTimestamp(user.last_login)}</div>
													</div>
													}
													{/*begin::Details item*/}
												</div>
											</div>
											{/*end::Details content*/}
										</div>
										{/*end::Card body*/}
									</div>
									{/*end::Card*/}
								</div>
								{/*end::Sidebar*/}
								{/*begin::Content*/}
								<div className="flex-lg-row-fluid ms-lg-15">
									<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fwt-bold mb-8">
										{user.is_client &&  <li className="nav-item">
											<a className={currentTab === 1 ? "nav-link text-active-primary pb-2 active" : "nav-link text-active-primary pb-2"} onClick={() => setCurrentTab(1)}>{parseInt(loggedInUser.id) === parseInt(id) ? 'My Assets' : `${userProfile.first_name}'s Assets`}</a>
										</li>}
										<li className="nav-item">
											<a className={currentTab === 2 ? "nav-link text-active-primary pb-2 active" : "nav-link text-active-primary pb-2"} onClick={() => setCurrentTab(2)}>Security</a>
										</li>
										{(id === user.id || permissions['can_read_user_logs']) && 
										<li className="nav-item">
											<a className={currentTab === 3 ? "nav-link text-active-primary pb-2 active" : "nav-link text-active-primary pb-2"} onClick={() => setCurrentTab(3)}>Event Logs</a>
										</li>}
									</ul>
									{/*end:::Tabs*/}
									{/*begin:::Tab content*/}
									<div className="tab-content" id="myTabContent">
										{/*begin:::Tab pane*/}
										{user.is_client && <div className={currentTab === 1 ? "tab-pane fade show active" : "tab-pane fade show"}>
											<ClientAssets />
										</div>}
										{/*end:::Tab pane*/}
										{/*begin:::Tab pane*/}
										<div  className={currentTab === 2 ? "tab-pane fade show active" : "tab-pane fade show"}>
											{/*begin::Card*/}
											<div className="card pt-4 mb-6 mb-xl-9">
												{/*begin::Card header*/}
												<div className="card-header border-0">
													{/*begin::Card title*/}
													<div className="card-title">
														<h3>User Security Details</h3>
													</div>
													{/*end::Card title*/}
												</div>
												{/*end::Card header*/}
												{/*begin::Card body*/}
												<div className="card-body pt-0 pb-5">
													{/*begin::Table wrapper*/}
													<div className="table-responsive">
														{/*begin::Table*/}
														<table className="align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
															{/*begin::Table body*/}
															<tbody className="fs-6 fwt-bold text-gray-600">
																<tr>
																	<td>Email</td>
																	<td>{user.email}</td>
																	<td className="text-end">
																		{(id === user.id || permissions['change_user']) && <button type="button" i-attr = 'btn-modal' 
																		className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" 
																		onClick={(evt) => 
																		{
																			evt.preventDefault()
																			setEmailChangeModalShow(true)
																		}}>
																			{/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</button>}
																	</td>
																</tr>
																<tr>
																	<td>Password</td>
																	<td>******</td>
																	<td className="text-end">
																		{user.id === loggedInUser.id ? 
																			permissions['change_user'] && 
																			<button type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" 
																				onClick = {(evt) => {
																					evt.preventDefault()
																					setPasswordModalShow(true)
																				}}>
																				<span className="svg-icon svg-icon-3">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																						<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																					</svg>
																				</span>
																			</button> :
																			permissions['change_user'] && <a  onClick={handleShowResetPasswordAssertiveModal} className="btn btn-sm btn-light-primary" i-attr="btn-modal">Reset Password For {userProfile.first_name}</a>
																		}
																	</td>
																</tr>
																<tr>
																	<td>{groups.length > 1 ? 'Roles' : 'Role'}</td>
																	<td>{rolesStr}</td>
																	<td className="text-end">
																		{/* <button type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																		</button> */}
																	</td>
																</tr>
															</tbody>
															{/*end::Table body*/}
														</table>
														{/*end::Table*/}
													</div>
													{/*end::Table wrapper*/}
												</div>
												{/*end::Card body*/}
											</div>
											{/*end::Card*/}
											<div >
									{/*begin::Table Widget 8*/}
									
									{permissions['view_accountapproval'] && 
									<div className="card card-xl-stretch mb-5 mb-xl-8">
										{/*begin::Header*/}
										<div className="card-header border-0 pt-2">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fwt-bolder fs-5 mb-1">{latestAccountApproval.length > 1 ? "Account Approvals" : "Account Approval"}</span>
											</h3>
										</div>
										{/*end::Header*/}
										{/*begin::Body*/}
										<div className="card-body py-3 special-data-card-bodies">
											<div className="tab-content">
												{/*begin::Tap pane*/}
												<div className="tab-pane fade show active" id="kt_table_widget_8_tab_1">
													{/*begin::Table container*/}
													{latestAccountApproval.length > 0 ?
													<div className="table-responsive">
														{/*begin::Table*/}
														<table className="align-middle gs-0 gy-3">
															{/*begin::Table head*/}
															<thead>
																<tr>
																	<th className="p-0 w-50px"></th>
																	<th className="p-0 min-w-150px"></th>
																	<th className="p-0 min-w-120px"></th>
																</tr>
															</thead>
															{/*end::Table head*/}
															{/*begin::Table body*/}
															<tbody>
																{latestAccountApproval && latestAccountApproval.map((approval) => {
																return (<tr>
																	<td>
																		<div className="symbol symbol-50px me-2">
																			<span className="symbol-label bg-light-danger">
																				{/*begin::Svg Icon | path: icons/duotune/coding/cod002.svg*/}
																				<span className="svg-icon svg-icon-2x svg-icon-danger">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<path opacity="0.3" d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z" fill="black"></path>
																						<path d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z" fill="black"></path>
																						<path d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z" fill="black"></path>
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</span>
																		</div>
																	</td>
																	{approval.unapproved_by == null ?
																	<td>
																		<a className="text-dark fw-bold text-hover-primary mb-1 fs-6">First Account Approval</a>
																		<span className="text-muted fwt-bold d-block fs-7">Unique ID: {approval.unique_url_string}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Account was approved by: {approval.approvee_name}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Date of approval: {parseTimestamp(approval.aprroved_on)}</span>
																	</td> : 
																	<>
																	<td>
																		<a className="text-dark fw-bold text-hover-primary mb-1 fs-6">Account Unapproval</a>
																		<span className="text-muted fwt-bold d-block fs-7">Unique ID: {approval.unique_url_string}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Account was unapproved by: {approval.unapprovee_name}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Date of unapproval: {parseTimestamp(approval.created_on)}</span>
																	</td>
																	{approval.approved_by !== null && 
																	<td>
																		<a className="text-dark fw-bold text-hover-primary mb-1 fs-6">Account Approval</a>
																		<span className="text-muted fwt-bold d-block fs-7">Account was approved by: {approval.approvee_name}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Date of approval: {parseTimestamp(approval.aprroved_on)}</span>
																	</td>}
																	</>}
																	<hr />
																</tr>)})}
															</tbody>
															{/*end::Table body*/}
														</table>
													</div> : 
													<div style = {{textAlign: "center"}}>
															<p>There aren't any records for account approval for {user.profile ? user.profile.first_name : 'this user.'}</p>
													</div>}
													{/*end::Table*/}
												</div>
												{/*end::Tap pane*/}
											</div>
										</div>
										{/*end::Body*/}
									</div>}
									{/*end::Tables Widget 8*/}
								</div>

								<div >
									{/*begin::Table Widget 8*/}
									{permissions['view_forceresetpassword'] &&
									<div className="card card-xl-stretch mb-5 mb-xl-8">
										{/*begin::Header*/}
										<div className="card-header border-0 pt-2">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fwt-bolder fs-5 mb-1">{forceResetPasswords.length > 1 ? "Password Resets" : "Password Reset"}</span>
											</h3>
										</div>
										{/*end::Header*/}
										{/*begin::Body*/}
										<div className="card-body py-3 special-data-card-bodies">
											<div className="tab-content">
												{/*begin::Tap pane*/}
												<div className="tab-pane fade show active" id="kt_table_widget_8_tab_1">
													{/*begin::Table container*/}
													{forceResetPasswords.length > 0 ?
													<div className="table-responsive">
														{/*begin::Table*/}
														<table className="align-middle gs-0 gy-3">
															{/*begin::Table head*/}
															<thead>
																<tr>
																	<th className="p-0 w-50px"></th>
																	<th className="p-0 min-w-150px"></th>
																	<th className="p-0 min-w-120px"></th>
																</tr>
															</thead>
															{/*end::Table head*/}
															{/*begin::Table body*/}
															<tbody>
																{forceResetPasswords && forceResetPasswords.map((reset) => {
																return (
																<tr>
																	<td>
																		<div className="symbol symbol-50px me-2">
																			<span className="symbol-label bg-light-warning">
																				<span className="svg-icon svg-icon-2x svg-icon-warning">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000"></rect>
																							<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
																							<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
																							<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
																						</g>
																					</svg>
																				</span>
																			</span>
																		</div>
																	</td>
																	<td>
																		<a className="text-dark fw-bold text-hover-primary mb-1 fs-6">Unique ID: {reset.unique_url_string}</a>
																		<span className="text-muted fwt-bold d-block fs-7">Reset By: {reset.reset_by_name}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Reset Complete: {reset.reset_done ? 'Yes' : 'No'}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Date of reset request: {parseTimestamp(reset.reset_on)}</span>
																		{reset.reset_done && <span className="text-muted fwt-bold d-block fs-7">Date of reset: {parseTimestamp(reset.updated_on)}</span>}
																	</td>
																</tr>)})}
															</tbody>
														</table>
													</div>:  <div style = {{textAlign: "center"}}>
																	<p>There aren't any records for reset passwords for {user.profile ? user.profile.first_name : 'this user.'}</p>
															</div>}
													{/*end::Table*/}
												</div>
												{/*end::Tap pane*/}
											</div>
										</div>
										{/*end::Body*/}
									</div>}
									{/*end::Tables Widget 8*/}
								</div>

								<div >
									{/*begin::Table Widget 8*/}
									{permissions['view_useraccountstatus'] &&
									<div className="card card-xl-stretch mb-5 mb-xl-8">
										{/*begin::Header*/}
										<div className="card-header border-0 pt-2">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fwt-bolder fs-5 mb-1">{latestAccountStatus.length > 1 ? "Account Activity Statuses" : "Account Activity Status"}</span>
											</h3>
										</div>
										{/*end::Header*/}
										{/*begin::Body*/}
										<div className="card-body py-3 special-data-card-bodies">
											<div className="tab-content">
												{/*begin::Tap pane*/}
												<div className="tab-pane fade show active" id="kt_table_widget_8_tab_1">
													{/*begin::Table container*/}
													{latestAccountStatus.length > 0 ?
													<div className="table-responsive">
														{/*begin::Table*/}
														<table className="align-middle gs-0 gy-3">
															{/*begin::Table head*/}
															<thead>
																<tr>
																	<th className="p-0 w-50px"></th>
																	<th className="p-0 min-w-150px"></th>
																	<th className="p-0 min-w-120px"></th>
																</tr>
															</thead>
															{/*end::Table head*/}
															{/*begin::Table body*/}
															<tbody>
																{latestAccountStatus && latestAccountStatus.map((status) => {
																return (<tr>
																	<td>
																		<div className="symbol symbol-50px me-2">
																		<span className="symbol-label bg-light-success">
																				<span className="svg-icon svg-icon-2x svg-icon-success">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<path opacity="0.3" d="M20.859 12.596L17.736 13.596L10.388 20.944C10.2915 21.0406 10.1769 21.1172 10.0508 21.1695C9.9247 21.2218 9.78953 21.2486 9.65302 21.2486C9.5165 21.2486 9.3813 21.2218 9.25519 21.1695C9.12907 21.1172 9.01449 21.0406 8.918 20.944L2.29999 14.3229C2.10543 14.1278 1.99619 13.8635 1.99619 13.588C1.99619 13.3124 2.10543 13.0481 2.29999 12.853L11.853 3.29999C11.9495 3.20341 12.0641 3.12679 12.1902 3.07452C12.3163 3.02225 12.4515 2.9953 12.588 2.9953C12.7245 2.9953 12.8597 3.02225 12.9858 3.07452C13.1119 3.12679 13.2265 3.20341 13.323 3.29999L21.199 11.176C21.3036 11.2791 21.3797 11.4075 21.4201 11.5486C21.4605 11.6898 21.4637 11.8391 21.4295 11.9819C21.3953 12.1247 21.3249 12.2562 21.2249 12.3638C21.125 12.4714 20.9989 12.5514 20.859 12.596Z" fill="black"></path>
																						<path d="M14.8 10.184C14.7447 10.1843 14.6895 10.1796 14.635 10.1699L5.816 8.69997C5.55436 8.65634 5.32077 8.51055 5.16661 8.29469C5.01246 8.07884 4.95035 7.8106 4.99397 7.54897C5.0376 7.28733 5.18339 7.05371 5.39925 6.89955C5.6151 6.7454 5.88334 6.68332 6.14498 6.72694L14.963 8.19692C15.2112 8.23733 15.435 8.36982 15.59 8.56789C15.7449 8.76596 15.8195 9.01502 15.7989 9.26564C15.7784 9.51626 15.6642 9.75001 15.479 9.92018C15.2939 10.0904 15.0514 10.1846 14.8 10.184ZM17 18.6229C17 19.0281 17.0985 19.4272 17.287 19.7859C17.4755 20.1446 17.7484 20.4521 18.0821 20.6819C18.4158 20.9117 18.8004 21.0571 19.2027 21.1052C19.605 21.1534 20.0131 21.103 20.3916 20.9585C20.7702 20.814 21.1079 20.5797 21.3758 20.2757C21.6437 19.9716 21.8336 19.607 21.9293 19.2133C22.025 18.8195 22.0235 18.4085 21.925 18.0154C21.8266 17.6223 21.634 17.259 21.364 16.9569L19.843 15.257C19.7999 15.2085 19.7471 15.1697 19.688 15.1432C19.6289 15.1167 19.5648 15.1029 19.5 15.1029C19.4352 15.1029 19.3711 15.1167 19.312 15.1432C19.2529 15.1697 19.2001 15.2085 19.157 15.257L17.636 16.9569C17.2254 17.4146 16.9988 18.0081 17 18.6229ZM10.388 20.9409L17.736 13.5929H1.99999C1.99921 13.7291 2.02532 13.8643 2.0768 13.9904C2.12828 14.1165 2.2041 14.2311 2.29997 14.3279L8.91399 20.9409C9.01055 21.0381 9.12539 21.1152 9.25188 21.1679C9.37836 21.2205 9.51399 21.2476 9.65099 21.2476C9.78798 21.2476 9.92361 21.2205 10.0501 21.1679C10.1766 21.1152 10.2914 21.0381 10.388 20.9409Z" fill="black"></path>
																					</svg>
																				</span>
																			</span>
																		</div>
																	</td>
																	<td>
																		<a className="text-dark fw-bold text-hover-primary mb-1 fs-6">Account Deactivation</a>
																		<span className="text-muted fwt-bold d-block fs-7">Unique ID: {status.unique_url_string}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Account was deactivated by: {status.deactivated_by_name}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Date of deactivation: {parseTimestamp(status.created_on)}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Why the account was deactivated: {status.deactivation_reason}</span>
																	</td> {status.activated_by !== null ? 
																	<td>
																		<a className="text-dark fw-bold text-hover-primary mb-1 fs-6">Account Activation</a>
																		<span className="text-muted fwt-bold d-block fs-7">Account was activated by: {status.activated_by_name}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Date of activation: {parseTimestamp(status.status_changed_on)}</span>
																		<span className="text-muted fwt-bold d-block fs-7">Why the account was activated: {status.activation_reason}</span>
																	</td> : null }
																</tr>)})}
															</tbody>
															{/*end::Table body*/}
														</table>
													</div>: <div style = {{textAlign: "center"}}>
																	<p>There aren't any records for account activity status for {user.profile ? user.profile.first_name : 'this user.'}</p>
															</div>}
												</div>
												{/*end::Tap pane*/}
											</div>
										</div>
										{/*end::Body*/}
									</div>}
									{/*end::Tables Widget 8*/}
								</div>
										</div>
										{/*end:::Tab pane*/}
										{/*begin:::Tab pane*/}
										<div className={currentTab === 3 ? "tab-pane fade show active" : "tab-pane fade show"}>
											{/*begin::Card*/}
											<div className="card pt-4 mb-6 mb-xl-9">
												{/*begin::Card header*/}
												<div className="card-header border-0">
													{/*begin::Card title*/}
													<div className="card-title">
														<h2>Events</h2>
													</div>
													{/*end::Card title*/}
													{/*begin::Card toolbar*/}
													<div className="card-toolbar">
														{/*begin::Button*/}
														{/* <button type="button" className="btn btn-sm btn-light-primary">
														<span className="svg-icon svg-icon-3">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z" fill="black" />
																<path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z" fill="black" />
																<path opacity="0.3" d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z" fill="black" />
															</svg>
														</span>Download Report</button> */}
														{/*end::Button*/}
													</div>
													{/*end::Card toolbar*/}
												</div>
												{/*end::Card header*/}
												{/*begin::Card body*/}
												<div className="card-body py-0" style={{maxHeight: "420px", "overflow": "auto", marginBottom: "20px"}}>
													{/*begin::Table*/}
													<table className="align-middle table-row-dashed fs-6 text-gray-600 fwt-bold gy-5" id="kt_table_customers_events">
														{/*begin::Table body*/}
														<tbody>
															{/*begin::Table row*/}
															{userEventLogs && userEventLogs.map((event) =>{ 
																return (<tr>
																<td className="min-w-400px">{event.event_log}</td>
																<td className="pe-0 text-gray-600 text-end min-w-200px">{parseDateTimestamp(event.created_on)}</td>
															</tr>)})}
														</tbody>
														{/*end::Table body*/}
													</table>
													{/*end::Table*/}
												</div>
												{/*end::Card body*/}
											</div>
											{/*end::Card*/}
										</div>
										{/*end:::Tab pane*/}
									</div>
									{/*end:::Tab content*/}
								</div>
								{/*end::Content*/}
							</div>
							{/*end::Layout*/}
							{/*begin::Modals*/}
							<MainModal handleClose={() => setUserModalShow(false)} show={userModalShow} modalTitle={loggedInUser.id === user.id ? "Update your profile details" : `Update ${userProfile.first_name}'s profile details`}>
								<UpdateUserProfileModalBody updateSuccessState = {(updatedUserProfile, message) => {
									setUserProfile(updatedUserProfile)
									setUserModalShow(false)
									setRes({
										message: message,
										severity: "success",
										show: true
									})
								}} clause = {user.id === loggedInUser.id ? "Your" : `${userProfile.first_name}'s`} user_id={user.id} user_email={user.email} profile={userProfile} user_type={user.is_staff ? 'staff' : user.is_crew ? 'crew' : 'client'}/>
							</MainModal>
							{/*begin::Modal - Add schedule*/}
							<div className="modal fade" id="kt_modal_add_schedule" tabindex="-1" aria-hidden="true">
								{/*begin::Modal dialog*/}
								<div className="modal-dialog modal-dialog-centered mw-650px">
									{/*begin::Modal content*/}
									<div className="modal-content">
										{/*begin::Modal header*/}
										<div className="modal-header">
											{/*begin::Modal title*/}
											<h2 className="fwt-bolder">Add an Event</h2>
											{/*end::Modal title*/}
											{/*begin::Close*/}
											<div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
												{/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
												<span className="svg-icon svg-icon-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
														<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
													</svg>
												</span>
												{/*end::Svg Icon*/}
											</div>
											{/*end::Close*/}
										</div>
										{/*end::Modal header*/}
										{/*begin::Modal body*/}
										<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
											{/*begin::Form*/}
											<form id="kt_modal_add_schedule_form" className="form" action="#">
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-2">
														<span className="required">Date &amp; Time</span>
														<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Select a date &amp; time."></i>
													</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input className="form-control form-control-solid" placeholder="Pick date &amp; time" name="event_datetime" id="kt_modal_add_schedule_datepicker" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="required fs-6 fwt-bold form-label mb-2">Event Name</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input type="text" className="form-control form-control-solid" name="event_name" value="" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="required fs-6 fwt-bold form-label mb-2">Event Organiser</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input type="text" className="form-control form-control-solid" name="event_org" value="" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="required fs-6 fwt-bold form-label mb-2">Send Event Details To</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input id="kt_modal_add_schedule_tagify" type="text" className="form-control form-control-solid" name="event_invitees" value="e.smith@kpmg.com.au, melody@altbox.com" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Actions*/}
												<div className="text-center pt-15">
													<button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
													<button type="submit" className="btn btn-beysix" data-kt-users-modal-action="submit">
														<span className="indicator-label">Submit</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
												</div>
												{/*end::Actions*/}
											</form>
											{/*end::Form*/}
										</div>
										{/*end::Modal body*/}
									</div>
									{/*end::Modal content*/}
								</div>
								{/*end::Modal dialog*/}
							</div>
							{/*end::Modal - Add schedule*/}
							{/*begin::Modal - Add task*/}
							<div className="modal fade" id="kt_modal_add_task" tabindex="-1" aria-hidden="true">
								{/*begin::Modal dialog*/}
								<div className="modal-dialog modal-dialog-centered mw-650px">
									{/*begin::Modal content*/}
									<div className="modal-content">
										{/*begin::Modal header*/}
										<div className="modal-header">
											{/*begin::Modal title*/}
											<h2 className="fwt-bolder">Add a Task</h2>
											{/*end::Modal title*/}
											{/*begin::Close*/}
											<div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
												{/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
												<span className="svg-icon svg-icon-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
														<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
													</svg>
												</span>
												{/*end::Svg Icon*/}
											</div>
											{/*end::Close*/}
										</div>
										{/*end::Modal header*/}
										{/*begin::Modal body*/}
										<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
											{/*begin::Form*/}
											<form id="kt_modal_add_task_form" className="form" action="#">
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-2">
														<span className="required">Task Due Date</span>
														<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Select a due date."></i>
													</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input className="form-control form-control-solid" placeholder="Pick date" name="task_duedate" id="kt_modal_add_task_datepicker" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="required fs-6 fwt-bold form-label mb-2">Task Name</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input type="text" className="form-control form-control-solid" name="task_name" value="" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-2">Task Description</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<textarea className="form-control form-control-solid rounded-3"></textarea>
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Actions*/}
												<div className="text-center pt-15">
													<button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
													<button type="submit" className="btn btn-beysix" data-kt-users-modal-action="submit">
														<span className="indicator-label">Submit</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<MainModal confirmationInfo = {changeEmailStep && loggedInUser.id === user.id ? {promptText: "Please enter the OTP sent to your new email to proceed.", btnText: "Okay, Got it"} : null} handleClose={() => setEmailChangeModalShow(false)} show={emailChangeModalShow} modalTitle={loggedInUser.id === user.id ? "Update your email address" : `Update ${userProfile.first_name}'s email address`}>
								<UpdateUserEmailModalBody 
								handleSuccessfullChange = {(message, updatedUser = null) => {
									if (updatedUser) {
										setUser(updatedUser)
										setchangeEmailStep(false)
									}
									setEmailChangeModalShow(false)
									setRes({
										message: message,
										severity: "success",
										show: true
									})
								}}

								handleChangeStep = {(status) => {
									setchangeEmailStep(status)
								}}

								handleErrors = {(message) => {
									setRes({
										message: message,
										severity: "error",
										show: true
									})
								}}

								clause = {user.id === loggedInUser.id ? "Your" : `${userProfile.first_name}'s`} user_id={user.id} user_email={user.email} profile={userProfile} />
							</MainModal>
							{/*end::Modal - Update email*/}
							{/*begin::Modal - Update password*/}
							<MainModal  handleClose={() => setPasswordModalShow(false)} show={passwordModalShow} modalTitle={"Update your account password"}>
								<UpdateUserPasswordModalBody
								handleSuccess = {(message) => {
									setPasswordModalShow(false)
									setRes({
										message: message,
										severity: "success",
										show: true
									})
								}}
								
								handleErrors = {(message) => {
									setRes({
										message: message,
										severity: "error",
										show: true
									})
								}}

								user_id={user.id} />
							</MainModal>
							{/*end::Modal - Update password*/}
							{/*begin::Modal - Update role*/}
							<div className="modal fade" id="kt_modal_update_role" tabindex="-1" aria-hidden="true">
								{/*begin::Modal dialog*/}
								<div className="modal-dialog modal-dialog-centered mw-650px">
									{/*begin::Modal content*/}
									<div className="modal-content">
										{/*begin::Modal header*/}
										<div className="modal-header">
											{/*begin::Modal title*/}
											<h2 className="fwt-bolder">Update User Role</h2>
											{/*end::Modal title*/}
											{/*begin::Close*/}
											<div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
												{/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
												<span className="svg-icon svg-icon-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
														<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
													</svg>
												</span>
												{/*end::Svg Icon*/}
											</div>
											{/*end::Close*/}
										</div>
										{/*end::Modal header*/}
										{/*begin::Modal body*/}
										<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
											{/*begin::Form*/}
											<form id="kt_modal_update_role_form" className="form" action="#">
												{/*begin::Notice*/}
												{/*begin::Notice*/}
												<div className="notice d-flex bg-light-primary rounded border-beysix border border-dashed mb-9 p-6">
													{/*begin::Icon*/}
													{/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
													<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
															<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
															<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
														</svg>
													</span>
													{/*end::Svg Icon*/}
													{/*end::Icon*/}
													{/*begin::Wrapper*/}
													<div className="d-flex flex-stack flex-grow-1">
														{/*begin::Content*/}
														<div className="fwt-bold">
															<div className="fs-6 text-gray-700">Please note that reducing a user role rank, that user will lose all priviledges that was assigned to the previous role.</div>
														</div>
														{/*end::Content*/}
													</div>
													{/*end::Wrapper*/}
												</div>
												{/*end::Notice*/}
												{/*end::Notice*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-5">
														<span className="required">Select a user role</span>
													</label>
													{/*end::Label*/}
													{/*begin::Input row*/}
													<div className="d-flex">
														{/*begin::Radio*/}
														<div className="form-check form-check-custom form-check-solid">
															{/*begin::Input*/}
															<input className="form-check-input me-3" name="user_role" type="radio" value="0" id="kt_modal_update_role_option_0" checked='checked' />
															{/*end::Input*/}
															{/*begin::Label*/}
															<label className="form-check-label" htmlFor="kt_modal_update_role_option_0">
																<div className="fwt-bolder text-gray-800">Administrator</div>
																<div className="text-gray-600">Best for business owners and company administrators</div>
															</label>
															{/*end::Label*/}
														</div>
														{/*end::Radio*/}
													</div>
													{/*end::Input row*/}
													<div className='separator separator-dashed my-5'></div>
													{/*begin::Input row*/}
													<div className="d-flex">
														{/*begin::Radio*/}
														<div className="form-check form-check-custom form-check-solid">
															{/*begin::Input*/}
															<input className="form-check-input me-3" name="user_role" type="radio" value="1" id="kt_modal_update_role_option_1" />
															{/*end::Input*/}
															{/*begin::Label*/}
															<label className="form-check-label" htmlFor="kt_modal_update_role_option_1">
																<div className="fwt-bolder text-gray-800">Developer</div>
																<div className="text-gray-600">Best for developers or people primarily using the API</div>
															</label>
															{/*end::Label*/}
														</div>
														{/*end::Radio*/}
													</div>
													{/*end::Input row*/}
													<div className='separator separator-dashed my-5'></div>
													{/*begin::Input row*/}
													<div className="d-flex">
														{/*begin::Radio*/}
														<div className="form-check form-check-custom form-check-solid">
															{/*begin::Input*/}
															<input className="form-check-input me-3" name="user_role" type="radio" value="2" id="kt_modal_update_role_option_2" />
															{/*end::Input*/}
															{/*begin::Label*/}
															<label className="form-check-label" htmlFor="kt_modal_update_role_option_2">
																<div className="fwt-bolder text-gray-800">Analyst</div>
																<div className="text-gray-600">Best for people who need full access to analytics data, but don't need to update business settings</div>
															</label>
															{/*end::Label*/}
														</div>
														{/*end::Radio*/}
													</div>
													{/*end::Input row*/}
													<div className='separator separator-dashed my-5'></div>
													{/*begin::Input row*/}
													<div className="d-flex">
														{/*begin::Radio*/}
														<div className="form-check form-check-custom form-check-solid">
															{/*begin::Input*/}
															<input className="form-check-input me-3" name="user_role" type="radio" value="3" id="kt_modal_update_role_option_3" />
															{/*end::Input*/}
															{/*begin::Label*/}
															<label className="form-check-label" htmlFor="kt_modal_update_role_option_3">
																<div className="fwt-bolder text-gray-800">Support</div>
																<div className="text-gray-600">Best for employees who regularly refund payments and respond to disputes</div>
															</label>
															{/*end::Label*/}
														</div>
														{/*end::Radio*/}
													</div>
													{/*end::Input row*/}
													<div className='separator separator-dashed my-5'></div>
													{/*begin::Input row*/}
													<div className="d-flex">
														{/*begin::Radio*/}
														<div className="form-check form-check-custom form-check-solid">
															{/*begin::Input*/}
															<input className="form-check-input me-3" name="user_role" type="radio" value="4" id="kt_modal_update_role_option_4" />
															{/*end::Input*/}
															{/*begin::Label*/}
															<label className="form-check-label" htmlFor="kt_modal_update_role_option_4">
																<div className="fwt-bolder text-gray-800">Trial</div>
																<div className="text-gray-600">Best for people who need to preview content data, but don't need to make any updates</div>
															</label>
															{/*end::Label*/}
														</div>
														{/*end::Radio*/}
													</div>
													{/*end::Input row*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Actions*/}
												<div className="text-center pt-15">
													<button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
													<button type="submit" className="btn btn-beysix" data-kt-users-modal-action="submit">
														<span className="indicator-label">Submit</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
												</div>
												{/*end::Actions*/}
											</form>
											{/*end::Form*/}
										</div>
										{/*end::Modal body*/}
									</div>
									{/*end::Modal content*/}
								</div>
								{/*end::Modal dialog*/}
							</div>
							{/*end::Modal - Update role*/}
							{/*begin::Modal - Add task*/}
							<div className="modal fade" id="kt_modal_add_auth_app" tabindex="-1" aria-hidden="true">
								{/*begin::Modal dialog*/}
								<div className="modal-dialog modal-dialog-centered mw-650px">
									{/*begin::Modal content*/}
									<div className="modal-content">
										{/*begin::Modal header*/}
										<div className="modal-header">
											{/*begin::Modal title*/}
											<h2 className="fwt-bolder">Add Authenticator App</h2>
											{/*end::Modal title*/}
											{/*begin::Close*/}
											<div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
												{/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
												<span className="svg-icon svg-icon-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
														<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
													</svg>
												</span>
												{/*end::Svg Icon*/}
											</div>
											{/*end::Close*/}
										</div>
										{/*end::Modal header*/}
										{/*begin::Modal body*/}
										<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
											{/*begin::Content*/}
											<div className="fwt-bolder d-flex flex-column justify-content-center mb-5">
												{/*begin::Label*/}
												<div className="text-center mb-5" data-kt-add-auth-action="qr-code-label">Download the
												<a >Authenticator app</a>, add a new account, then scan this barcode to set up your account.</div>
												<div className="text-center mb-5 d-none" data-kt-add-auth-action="text-code-label">Download the
												<a >Authenticator app</a>, add a new account, then enter this code to set up your account.</div>
												{/*end::Label*/}
												{/*begin::QR code*/}
												<div className="d-flex flex-center" data-kt-add-auth-action="qr-code">
													<img src="assets/media/misc/qr-code.png" alt="Scan this QR code" />
												</div>
												{/*end::QR code*/}
												{/*begin::Text code*/}
												<div className="border rounded p-5 d-flex flex-center d-none" data-kt-add-auth-action="text-code">
													<div className="fs-1">gi2kdnb54is709j</div>
												</div>
												{/*end::Text code*/}
											</div>
											{/*end::Content*/}
											{/*begin::Action*/}
											<div className="d-flex flex-center">
												<div className="btn btn-light-primary" data-kt-add-auth-action="text-code-button">Enter code manually</div>
												<div className="btn btn-light-primary d-none" data-kt-add-auth-action="qr-code-button">Scan barcode instead</div>
											</div>
											{/*end::Action*/}
										</div>
										{/*end::Modal body*/}
									</div>
									{/*end::Modal content*/}
								</div>
								{/*end::Modal dialog*/}
							</div>
							{/*end::Modal - Add task*/}
							{/*begin::Modal - Add task*/}
							<div className="modal fade" id="kt_modal_add_one_time_password" tabindex="-1" aria-hidden="true">
								{/*begin::Modal dialog*/}
								<div className="modal-dialog modal-dialog-centered mw-650px">
									{/*begin::Modal content*/}
									<div className="modal-content">
										{/*begin::Modal header*/}
										<div className="modal-header">
											{/*begin::Modal title*/}
											<h2 className="fwt-bolder">Enable One Time Password</h2>
											{/*end::Modal title*/}
											{/*begin::Close*/}
											<div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
												{/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
												<span className="svg-icon svg-icon-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
														<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
													</svg>
												</span>
												{/*end::Svg Icon*/}
											</div>
											{/*end::Close*/}
										</div>
										{/*end::Modal header*/}
										{/*begin::Modal body*/}
										<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
											{/*begin::Form*/}
											<form className="form" id="kt_modal_add_one_time_password_form">
												{/*begin::Label*/}
												<div className="fwt-bolder mb-9">Enter the new phone number to receive an SMS to when you log in.</div>
												{/*end::Label*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-2">
														<span className="required">Mobile number</span>
														<i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="A valid mobile number is required to receive the one-time password to validate your account login."></i>
													</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input type="text" className="form-control form-control-solid" name="otp_mobile_number" placeholder="+6123 456 789" value="" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Separator*/}
												<div className="separator saperator-dashed my-5"></div>
												{/*end::Separator*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-2">
														<span className="required">Email</span>
													</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input type="email" className="form-control form-control-solid" name="otp_email" value="e.smith@kpmg.com.au" readonly="readonly" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Input group*/}
												<div className="fv-row mb-7">
													{/*begin::Label*/}
													<label className="fs-6 fwt-bold form-label mb-2">
														<span className="required">Confirm password</span>
													</label>
													{/*end::Label*/}
													{/*begin::Input*/}
													<input type="password" className="form-control form-control-solid" name="otp_confirm_password" value="" />
													{/*end::Input*/}
												</div>
												{/*end::Input group*/}
												{/*begin::Actions*/}
												<div className="text-center pt-15">
													<button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
													<button type="submit" className="btn btn-beysix" data-kt-users-modal-action="submit">
														<span className="indicator-label">Submit</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
												</div>
												{/*end::Actions*/}
											</form>
											{/*end::Form*/}
										</div>
										{/*end::Modal body*/}
									</div>
									{/*end::Modal content*/}
								</div>
								{/*end::Modal dialog*/}
							</div>
							{/*end::Modal - Add task*/}
							{/*end::Modals*/}
						</div>
						{/*end::Container*/}
					</div>
				: <RestrictedModal />}
		</div>
  )
}
