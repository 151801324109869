import React from 'react'
import SkeletonLoader from './SkeletonLoader/SkeletonLoader'
import NoResults from '../Utilities/NoResults';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import parseDateTimestamp from '../Utilities/parseDateTimeStamp.';
import { Link } from 'react-router-dom';

export default function AccessoryTable({accessories, resultsFound, loading}) {
    
    const permissions = useSelector((state) => state.loginSlice.permissions);

  return (
    <>
    {!loading ?
        resultsFound ?
            <div className="table-responsive">
                {/*begin::Table*/}
                <motion.table
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
                        >
                    {/*begin::Table head*/}
                    <thead>
                        <tr className="fw-bolder text-muted bg-light">
                            <th className="ps-4 min-w-300px rounded-start">Accessory Name</th>
                            <th className="min-w-125px">Created At</th>
                            <th className="min-w-125px">Created By</th>
                            <th className="min-w-200px">Description</th>
                            <th className="min-w-150px">Project</th>
                            {permissions['change_tool'] && <th className="min-w-200px text-end rounded-end"></th>}
                        </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody>
                        {accessories.map((accessory) => {
                        return (<tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px me-5">
                                        <span className="symbol-label bg-light">
                                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z" fill="black"></path><path d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="black"></path></svg></span>
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-start flex-column">
                                        <a className="text-dark fw-bolder mb-1 fs-6">{accessory.Accessory_name}</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a className="text-dark fw-bolder d-block mb-1 fs-6">{parseDateTimestamp(accessory.created_at)}</a>
                            </td>
                            <td>
                                <Link to={`/user-management/users/user-profile/${accessory.created_by}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{accessory.created_by_name}</Link>
                            </td>
                            <td>
                                {accessory.Accessory_description.length > 0 ? 
                                <a className="text-dark fw-bolder d-block mb-1 fs-6">{accessory.Accessory_description}</a> :
                                <a className="text-dark fw-bolder d-block mb-1 fs-6"> - </a>}
                            </td>
                            <td>
                            {accessory.project ?
                                <Link to={`/project-management/detailed-project/${accessory.project.id}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{accessory.project.name}</Link> :
                                <span className="text-dark fw-bolder d-block mb-1 fs-6">Not assigned</span>
                            }
                            </td>
                            {permissions['change_tool'] && <td className="text-end">
                                <a
                                onClick={() => {
                                setCurrentPk(accessory.id);
                                setEditAccessoryModal(true);
                                setModalOpen(true)
                                }}
                                className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"
                                >Edit</a>
                            </td>}
                        </tr>)})}
                    </tbody>
                    {/*end::Table body*/}
                </motion.table>
                {/*end::Table*/}
            </div> :
            <NoResults /> :
        <SkeletonLoader />}
    </>
  )
}
