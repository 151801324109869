import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import api from '../../store/api';
import FeedbackModal from '../Utilities/FeedbackModal';
import BrandSearchComponent from '../Utilities/BrandSearchComponent';
import ProjectSearchComponent from '../Utilities/ProjectSearchComponent';

export default function EditAccessory({handleCloseModal, primaryKey, accessoryData, setAccessoryData, modalOpen}) {
    const token = useSelector((state) => state.loginSlice.token);
    const [editAccessoryData, setEditAccessoryData] = useState({
        accessoryDescription: '',
        accessoryName: '',
        accessoryProject: null
    })

    useEffect(() => {
      if (!modalOpen) {
        setEdit(false)
      }
    }, [modalOpen])

    const [successfullSubmission, setsuccessfullSubmission] = useState(false)
    const handleChange = (evt) => {
        evt.preventDefault()
        const name = evt.target.name
        const value = evt.target.value;
        setEditAccessoryData({...editAccessoryData, [name]: value})
    }

    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

    const [projectName, setBrandName] = useState('')

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (editAccessoryData.accessoryName.length > 0) {
            setLoading(true)
            api.put(`/api/accessories/detail-accessory/${primaryKey}/`, editAccessoryData,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 200) {
                        setLoading(false)
                        setRes({
                            message: response.data.message,
                            severity: "success",
                            show: true
                        })
                        setEditAccessoryData({
                            accessoryDescription: '',
                            accessoryName: ''
                        })
                        const _accessoryData = accessoryData
                        for (let i = 0; i < _accessoryData.length; i++) {
                            if (_accessoryData[i].id === primaryKey) {
                              _accessoryData[i] = response.data.data
                            }
                        }
                        setAccessoryData(_accessoryData)
                        setTimeout(() => {
                            handleCloseModal();
                        }, 1000);
                      } 
                })
              .catch((error) => {
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
              })
        }
    }

    const getAccessoryData = () => {
        if (primaryKey) {
            api.get(`/api/accessories/detail-accessory/${primaryKey}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                setEditAccessoryData({
                    accessoryName: response.data.data.Accessory_name,
                    accessoryDescription: response.data.data.Accessory_description,
                    accessoryProject: response.data.data.project.id
                })
                setBrandName(response.data.data.Accessory_project_name)
                setEdit(true)
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
        }
    }

    const [edit, setEdit] = useState(null)

    useEffect(() => {
        getAccessoryData()
    }, [primaryKey])

  return (
    <div className="modal-body scroll-y mx-5 mx-xl-15" data-select2-id="select2-data-78-jwvd">
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
        {/*begin::Form*/}
        <form id="kt_modal_new_card_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_new_card_form">
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Accessory Name</span>
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="accessorytip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
                </label>
                {/*end::Label*/}
                <input type="text" className="form-control form-control-solid" placeholder="" name="accessoryName" value={editAccessoryData.accessoryName} onChange = {handleChange} />
            <div className="fv-plugins-message-container invalid-feedback"></div></div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <ProjectSearchComponent returnResults = {(id) => {
              if (id) {
                  setEditAccessoryData({...editAccessoryData, accessoryProject: id})
                }
            }} successfullSubmission={successfullSubmission} edit={edit} projectName={projectName}/>
            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="fs-6 fw-bold form-label mb-2">Accessory Description</label>
                {/*end::Label*/}
                {/*begin::Input wrapper*/}
                <div className="position-relative">
                    {/*begin::Input*/}
                    <textarea rows="5" cols="50" className="form-control form-control-solid" name="accessoryDescription" id="accessoryDescription" value={editAccessoryData.accessoryDescription} onChange={handleChange}></textarea>
                </div></div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            
            {/*end::Input group*/}
            {/*begin::Input group*/}
            
            {/*end::Input group*/}
            {/*begin::Actions*/}
            <div className="text-center pt-15">
                <button type="reset" id="kt_modal_new_card_cancel" className="btn btn-light me-3">Discard</button>
                <button type="submit" id="kt_modal_new_card_submit" onClick={handleSubmit} className="btn btn-beysix" disabled={!loading ? false : true}>
                    {!loading ? <span className="indicator-label">Submit</span>
                    : <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>
            </div>
            {/*end::Actions*/}
        <div></div></form>
        {/*end::Form*/}
    </div>
  )
}
