// loginSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';

export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
  try {
    const response = await api.post('/api/users/login/', credentials);
    const { user, token, profile, url, isAuthenticated, userSettings } = response.data;
    return { user, token, profile, url, isAuthenticated, userSettings };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const loginSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    token: null,
    error: null,
    permissions: null,
    userProfile: null,
    userSettings: null,
    redirectPath: null, 
  },

  reducers: {
    setRedirectPath: (state, action) => {
      state.redirectPath = action.payload;
    },
    resetLoginState: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = null;
      state.permissions = null;
      state.userProfile = null;
      state.userSettings = null;
      state.redirectPath = null;
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    updateUserProfile: (state, action) => {
      state.userProfile = { ...state.userProfile, ...action.payload };
    },
    updateUserSettings: (state, action) => {
      const setts = state.userSettings
      const payload_name = action.payload[0]['setting_name']
      let exists = false
      for (let i = 0; i < setts.length; i++) {
        if (setts[i]['setting_name']) {
          exists = true
          setts[i]['setting_value'] = action.payload[0]['setting_value']
          state.userSettings =  setts
          return
        }
      } 

      if (!exists)
        state.userSettings = [ ...state.userSettings, ...action.payload ];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
        state.redirectPath = action.payload.url;
        state.userProfile = action.payload.profile;
        state.permissions = action.payload.user ? action.payload.user.permissions : [];
        state.userSettings = action.payload.userSettings;
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        state.redirectPath = null;
        state.permissions = null;
        state.userSettings = null;
      });
  },
});

export const { setRedirectPath, resetLoginState, updateUser, updateUserProfile, updateUserSettings } = loginSlice.actions;
export default loginSlice.reducer;
