import React, {useEffect, useState, useRef} from 'react'
import { useSelector } from 'react-redux';
import api from '../../../store/api';
import getAssetSearchData from '../../Utilities/getAssetSearchData';
import { useParams } from 'react-router';
import { motion } from 'framer-motion';
import SkeletonLoader from '../../Assets/SkeletonLoader/SkeletonLoader';
import { Link } from 'react-router-dom';
import convertCm from '../../Utilities/convertCm';
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import NoResults from '../../Utilities/NoResults';


export default function AgencyAssets() {

  const [assets, setAssets] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const [resultsFound, setResultsFound] = useState(true)
  const token = useSelector((state) => state.loginSlice.token);
  const { id } = useParams()
  const tableRef = useRef(null)


  const getAssets = () => {
      setPageLoading(true)
      api.get(`/api/agencies/agency-assets/${id}`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
        if (response.data.length === 0) {
          setResultsFound(false)
        }
        setAssets(response.data)
        setOriginalAssets(response.data)
        setPageLoading(false)
      })
      .catch((error) => {
          throw new Error(error) 
      })
  }

  useEffect(() => {
    getAssets()
  }, [])

  const [searchQuery, setSearchQuery] = useState('')
  const [originalAssets, setOriginalAssets] = useState([])
  const handleSearchChange = async (e) => {
    const keyword = e.target.value;
    if (keyword.length === 0) {
      setAssets(originalAssets)
    } else {
      setSearchQuery(keyword)
      const assets = await getAssetSearchData(keyword, `/api/agencies/agency-assets/search/${id}/`, token)
      setAssets(assets)
    }
  }

  return (
    <>
      {!pageLoading ? 
      resultsFound ?
      <div className="table-responsive"
        ref={tableRef}
        style={{ height: '70vh', overflowY: 'auto' }}
      >
          {/*begin::Table*/}
            <motion.table
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
              >
            {/*begin::Table head*/}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-300px">Asset Name</th>
                <th className="min-w-150px">Warehouse</th>
                <th className="min-w-150px">Zone</th>
                <th className="min-w-150px">Brand</th>
                <th className="min-w-150px">Client</th>
                <th className="min-w-150px">Project</th>
                <th className="min-w-150px">Asset Owner</th>
                <th className="min-w-150px">Asset Length</th>
                <th className="min-w-150px">Asset Width</th>
                <th className="min-w-150px">Asset Height</th>
                <th className="min-w-150px">Created at</th>
                <th className="min-w-150px">Created by</th>
              </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
              {assets.map((asset, index) => 
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {!asset.first_image ? <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path>
                    <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span> :
                    <span className='symbol-label bg-light'>
                      <img src={asset.first_image.image_url} className="h-100 align-self-end" alt="" style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                    </span>}
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <Link to={`/asset-management/detailed-asset/${asset.id}`} className="text-dark fw-bolder text-hover-primary fs-6">{asset.asset_name}</Link>
                      {asset.active ? <span style={{maxWidth: 'fit-content'}} className="mt-2 fw-bold badge badge-success badge-sm d-block text-white">Active</span>: 
                      <span style={{maxWidth: 'fit-content'}} className="mt-2 fw-bold badge badge-danger badge-sm d-block text-white">Inactive</span>}
                    </div>
                  </div>
                  <div className="dropdown">
                  <div id={`ddAsset${index}`} className="dropdown-content" style={{
                      zIndex: 105,
                      position: 'fixed',
                      inset: '0px 0px auto auto',
                      margin: '0px',
                      transform: 'translate3d(-43vw, 290px, 0px)',
                      maxHeight: '300px',
                      overflow: 'auto'
                  }}>
                    {(asset.assets) && asset.assets.map((asset, index) => {
                      return (
                        <Link to={`/asset-management/detailed-asset/${asset.id}`} key={index}>{asset.asset_name}</Link>
                      )
                    })}
                  </div>
                </div>
                </td>
                <td>
                  {asset.asset_warehouse ? <Link to={`/warehouse-management/warehouses/detailed-warehouse/${asset.asset_warehouse.warehouse_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_warehouse.warehouse_name}</Link> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  {asset.asset_zone ? <a  className="text-dark fw-bolder d-block fs-6">{asset.asset_zone}</a> : <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  <Link to={`/brand-management/detailed-brand/${asset.asset_brand.brand_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_brand.brand_name}</Link>
                </td>
                <td>
                  {asset.asset_client ?
                    <Link to={`/agency-management/agencies/detailed-agency-organization/${asset.asset_client.client_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_client.client_name}</Link> :
                    <a  className="text-dark fw-bolder d-block fs-6">Not added</a>
                  }
                </td>
                <td>
                  <Link to={`/project-management/detailed-project/${asset.asset_project.project_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.asset_project.project_name}</Link>
                </td>
                <td>
                  <Link to={`/user-management/users/user-profile/${asset.brand_representative.brand_representative_id}`} className="text-dark fw-bolder text-hover-primary d-block fs-6">{asset.brand_representative.brand_representative_name}</Link>
                </td>
                <td>
                  {asset.asset_length ? <a  className="text-dark fw-bolder d-block fs-6">{convertCm(asset.asset_length)}</a> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  {asset.asset_width ? <a  className="text-dark fw-bolder d-block fs-6">{convertCm(asset.asset_width)}</a> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  {asset.asset_height ? <a  className="text-dark fw-bolder d-block fs-6">{convertCm(asset.asset_height)}</a> :
                  <a  className="text-dark fw-bolder d-block fs-6">Not added</a>}
                </td>
                <td>
                  <a className="text-dark fw-bolder d-block fs-6">{parseDateTimestamp(asset.created_at)}</a>
                </td>
                <td>
                  <Link to={`/user-management/users/user-profile/${asset.created_by}`} className="text-dark text-hover-primary fw-bolder d-block fs-6">{asset.created_by_name}</Link>
                </td>
              </tr>)}
            </tbody>
            {/*end::Table body*/}
          </motion.table>
          </div> : 
          <NoResults />:
            <SkeletonLoader />}
    </>
  )
}
