import React, {useState, useEffect} from 'react'
import MainModal from '../Modals/MainModal'
import CreateAccessory from './CreateAccessory'
import api from '../../store/api'
import { useSelector } from 'react-redux'
import EditAccessory from './EditAccessory'
import BulkUpload from './BulkUpload'
import AccessoryTable from './AccessoryTable'
import { motion } from 'framer-motion'

export default function AccessoryManagement() {

    const [accessoryModal, setAccessoryModal] = useState(false)
    const [editAccessoryModal, setEditAccessoryModal] = useState(false)
    const [currentPk, setCurrentPk] = useState(null)
    const [accessories, setAccessories] = useState([])
    const [bulkUploadModal, setBulkUploadModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [ogAccessories, setOgAccessories] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const [resultsFound, setResultsFound] = useState(true)
    const [count, setCount] = useState(0)

    const token = useSelector((state) => state.loginSlice.token);
    const permissions = useSelector((state) => state.loginSlice.permissions);
    const getAccessories = () => {
        setPageLoading(true)
        api.get('/api/accessories/accessory/',
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            if (response.status === 200) {
                if (response.data.results.length > 0){
                    setAccessories(response.data.results)
                    setOgAccessories(response.data.results)
                    setCount(response.data.count)
                } else {
                    setAccessories(response.data.results)
                    setResultsFound(false)
                }
            setPageLoading(false)
            }
        })
        .catch((error) => {
            setPageLoading(false)
            throw new Error('Error getting data.')
        })
    }

    const handleSearch = (evt) => {
        evt.preventDefault()
        const value = evt.target.value
        setSearchQuery(value)
        if (value.length > 2) {
            setPageLoading(true)
            api.post(`/api/accessories/detailed-search-accessory/`, {searchQuery: value},
                {headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0){
                            setAccessories(response.data.results)
                            setResultsFound(true)
                        } else {
                            setAccessories(response.data.results)
                            setResultsFound(false)
                        }
                        setPageLoading(false)
                    }
                })
                .catch((error) => {
                    if (response.status === 404) {

                    } else {
                        throw new Error(error)
                    }
                    setPageLoading(true)
                })
            } else {
                setAccessories(ogAccessories)
                if (ogAccessories.length > 0)
                    setResultsFound(true)
            }
    }

    useEffect(() => {
        getAccessories()
    }, [])

    const handleCloseModal = () => {
        setAccessoryModal(false)
        setEditAccessoryModal(false)
        setCurrentPk(null)
        setModalOpen(false)
    }

    const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
    {!pageLoading && <motion.p 
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}style={{fontWeight: '600'}}>{count} Accessories Total</motion.p>}
    <div className="card mb-5 mb-xl-8">
        <MainModal 
            handleClose={() => setAccessoryModal(false)} 
            show={accessoryModal}
            modalTitle='Create a new accessory'>
            <CreateAccessory 
              handleCloseModal={handleCloseModal} 
              setAccessories={setAccessories} 
              accessories={accessories} />
        </MainModal>
        <MainModal 
            handleClose={() => setBulkUploadModal(false)} 
            show={bulkUploadModal}
            modalTitle='Bulk Upload Accessories'>
            <BulkUpload setAccessories={
                (accessories) => setAccessories(accessories)
            }/>
        </MainModal>
        <MainModal 
            handleClose={() => {
                setEditAccessoryModal(false)
                setCurrentPk(null)
                setModalOpen(false)
            }} 
            show={editAccessoryModal}
            modalTitle='Edit accessory'>
            <EditAccessory 
              modalOpen={modalOpen} 
              handleCloseModal={handleCloseModal} 
              primaryKey={currentPk} 
              setAccessoryData={setAccessories} 
              accessoryData={accessories} />
        </MainModal>
    <div className="card-header border-0 pt-5">
    <h3 className="card-title align-items-start flex-column">
    <div className="d-flex align-items-center position-relative my-1">
        <span className="svg-icon svg-icon-1 position-absolute ms-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path></svg>
        </span>
        <input onChange={handleSearch} 
        type="text" 
        className="form-control form-control-solid w-250px ps-14" 
        placeholder="Search Accessory" />
        </div>
    </h3>
    {permissions['add_accessory'] && 
    <div className="card-accessorybar">
    <a onClick={() => setBulkUploadModal(true)} className="btn btn-active-dark ftw-bold">
    <span className="svg-icon svg-icon-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z" fill="black"/>
        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
        </svg>
    </span>
    Bulk upload Accessories</a>
    <a className="btn btn-sm btn-light-primary" onClick={() => setAccessoryModal(true)}>
    <span className="svg-icon svg-icon-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
            </svg>
        </span>
        Create Accessory
    </a>

        {/*begin::Menu 2*/}
        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
        </div></div>}
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    <div className="card-body py-3">
    {/*begin::Table container*/}
        <AccessoryTable accessories={accessories}  loading={pageLoading} resultsFound={resultsFound} />
    {/*end::Table container*/}
    </div>
    {/*begin::Body*/}
    </div>
    </>
  )
}

